 // View all valid theme colors inside MatxTheme/themeColors.js
const Layout2Settings = {
  leftSidebar: {
    show: true,
    mode: "full", // full, close, compact, mobile,
    theme: "whiteGreen",
    bgImgURL: "/static/assets/images/sidebar/sidebar-bg-dark.jpg"
  },
  topbar: {
    show: true,
    fixed: true,
    theme: "whiteGreen"
  }
};

export default Layout2Settings;
