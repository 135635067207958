import { themes } from '../MatxTheme/initThemes';
import layout2Settings from './Layout2/Layout2Settings';

// UPDATE BELOW CODE
// DOC http://demos.ui-lib.com/matx-react-doc/layout.html
export const MatxLayoutSettings = {
  activeLayout: 'layout2', // layout1, layout2
  activeTheme: 'whiteGreen', // View all valid theme colors inside MatxTheme/themeColors.js
  perfectScrollbar: false,

  themes: themes,
  layout2Settings,

  secondarySidebar: {
    show: false,
    open: false,
    theme: 'whiteGreen'
  },

  // Footer options
  footer: {
    show: false,
    fixed: false,
    theme: 'whiteGreen'
  }
};
