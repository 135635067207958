// import useSettings from "app/hooks/useSettings";
import greenlightIcon from "./greenlight-icon.svg";

export default function MatxLogo({ className }) {
  // const { settings } = useSettings();
  // const theme = settings.themes[settings.activeTheme];

  return (
    <img
      src={greenlightIcon}
      alt="Greenlight Logo"
      width="36px"
      height="36px"
      className={className}
    />
  );
}
